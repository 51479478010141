import { useAds } from '@xing-com/crate-ads-native-ads';
import type {
  MatchingReasonsResult,
  Maybe,
} from '@xing-com/crate-common-graphql-types';
import { AdTypes } from '@xing-com/crate-common-graphql-types';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import {
  SEARCH_ALERT_COUNT_PARAM,
  SEARCH_QUERY_ID_PARAM,
} from '@xing-com/crate-jobs-constants';
import {
  useSearchState,
  type JobRecommendationVisibleJob,
} from '@xing-com/crate-jobs-hooks';
import { useFeatures } from '@xing-com/hub';

import { matchJobMatchingReasons } from '../../../helpers';
import { useJobMatchingReasons } from '../../../hooks';
import { Ad } from '../ad';
import { AIFeedback } from '../ai-feedback';
import type { ItemWithBanners } from '../helpers';
import { Result } from '../result';
import * as S from '../results.styles';

export type ListType = 'SEARCH' | 'EMPTY_STATE_SEARCH' | 'EMPTY_STATE_RECOS';

type Props = {
  items: (ItemWithBanners | JobRecommendationVisibleJob)[];
  type: ListType;
  slugList: string[];
  /**
   * The tracking token to be forwarded to the AI feedback. It should be the
   * trackingToken of the first item in the list
   */
  trackingToken?: string;
  matchingReasons?: Maybe<MatchingReasonsResult>;
};

export const ResultsList: React.FC<Props> = ({
  items,
  type,
  slugList,
  trackingToken,
  matchingReasons,
}) => {
  const features = useFeatures();
  const { getSearchParameter } = useSearchParameter();
  const { searchQuery } = useSearchState();
  const { isLoggedOut } = useLoginState();
  const { backgroundJobId, jobPostingIds: matchingReasonsIds = [] } =
    matchingReasons ?? {};

  const searchAlertCount = Number(getSearchParameter(SEARCH_ALERT_COUNT_PARAM));
  // We can assume that the searchId is always defined at this point,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const searchId = getSearchParameter(SEARCH_QUERY_ID_PARAM)!;
  const isBasic = features.data?.isBasic;

  let count = 0;
  const itemsWithIsNew = items.map((item) => {
    if (
      item === 'ProJobsUpsellBanner' ||
      item === 'AISearchFeedbackBanner' ||
      item === 'Ad'
    ) {
      return item;
    }

    count++;
    return { ...item, isNew: searchAlertCount >= count };
  });

  const { ads } = useAds({
    adCount: 1,
    adTypes: [AdTypes.VideoAd, AdTypes.WebsiteAd],
    channel: 'jobs_search_web',
    skipQuery: isLoggedOut,
  });
  const ad = ads.length > 0 && ads[0];

  const matchingReasonsData = useJobMatchingReasons(backgroundJobId);
  // Safe to create this variable here, so that every time the list is
  // rendered, the index is calculated again.
  // The index is required for the tracking flags
  let matchingReasonsIndex = 1;

  return (
    <S.List>
      {itemsWithIsNew.map((result, position) => {
        if (result === 'ProJobsUpsellBanner') {
          return (
            <li key="projobs-banner">
              <S.ProJobsUpsellBanner isBasic={isBasic} />
            </li>
          );
        }

        if (result === 'AISearchFeedbackBanner') {
          return (
            <li key="ai-feedback-banner">
              <AIFeedback searchId={searchId} trackingToken={trackingToken} />
            </li>
          );
        }

        if (result === 'Ad') {
          if (!ad) return null;

          return (
            <li key="ad">
              <Ad ad={ad} position={position} />
            </li>
          );
        }

        const { isNew, ...item } = result;
        const matchingReasons = matchJobMatchingReasons(
          result,
          matchingReasonsIds,
          matchingReasonsData,
          matchingReasonsIndex
        );
        if (matchingReasons) {
          matchingReasonsIndex++;
        }

        return (
          <Result
            key={item.trackingToken}
            item={item}
            type={type}
            isNew={isNew}
            slugList={slugList}
            searchQuery={searchQuery}
            matchingReasons={matchingReasons}
          />
        );
      })}
    </S.List>
  );
};
